import React, { forwardRef } from "react";

import PropTypes from "prop-types";

// DateJS
import dayjs from "dayjs";

// @mui material components
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

import MDTypography from "components/MDTypography";

const PrintableContents = forwardRef(({ orderDetails }, ref) => {

  return (
    <div ref={ref} className="printable-content">
      <Grid container spacing={2}>
        <Grid item xs={12}><MDTypography variant="h3">#{orderDetails.external_order_id} / {orderDetails.customer_name}</MDTypography></Grid>
        <Grid item xs={12}>
          {orderDetails.notes ? (
            <MDTypography variant="body2">
              Notes : {orderDetails.notes}
            </MDTypography>
          ) : null}
        </Grid>
        <Grid item xs={4}>
          Delivery Date : {orderDetails.delivery_date}
        </Grid>
        <Grid item xs={4}>
          Dispatch Time : {dayjs(`1970-01-01 ${orderDetails.dispatch_time}`).format("hh:mm A")}
        </Grid>
        <Grid item xs={4}>
          Delivery ETA : {dayjs(`1970-01-01 ${orderDetails.delivery_eta}`).format("hh:mm A")}
        </Grid>
        <Grid item xs={12}>
          <TableContainer sx={{ boxShadow: "none", borderRadius: 0 }}>
            <Table aria-label="order lines table">
              <TableBody>
                {orderDetails.order_line_items.map((orderLineItem, index) => (
                  <React.Fragment key={`${orderLineItem.id}-${index}-item`}>
                    {orderLineItem.subitems.length === 0 &&
                    !orderLineItem.item_description.toLowerCase().includes("upgrade") ? (
                        <TableRow key={`${orderLineItem.id}-${index}-xitem`}>
                          <TableCell scope="row">
                            <MDTypography variant="body2">
                              {orderLineItem.item_quantity} x {orderLineItem.item_description}
                            </MDTypography>
                          </TableCell>
                        </TableRow>
                      ): null }

                    {orderLineItem.subitems.length !== 0 && orderLineItem.subitems.map((subitem, index) => (
                      <TableRow key={`${subitem.id}-${index}-subitem`}>
                        <TableCell scope="row">
                          <MDTypography variant="body2">
                            {subitem.item_quantity} x {subitem.item_description}
                          </MDTypography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </div>
  );
});

PrintableContents.propTypes = {
  orderDetails: PropTypes.shape({
    customer_name: PropTypes.string.isRequired,
    external_order_id: PropTypes.number.isRequired,
    notes: PropTypes.string,
    delivery_date: PropTypes.string.isRequired,
    dispatch_time: PropTypes.string.isRequired,
    delivery_eta: PropTypes.string.isRequired,
    order_line_items: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      subitems: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        item_quantity: PropTypes.number.isRequired,
        item_description: PropTypes.string.isRequired,
      })),
      item_quantity: PropTypes.number.isRequired,
      item_description: PropTypes.string.isRequired,
    })),
  }),
};

PrintableContents.displayName = "PrintableContents";

export default PrintableContents;
