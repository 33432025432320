import { useState, useEffect, useContext } from "react";

// react-router components
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 React example components
import Sidenav from "./components/Sidenav";
import MDSnackbar from "./components/MDSnackbar";

// Material Dashboard 2 React themes
import theme from "./assets/theme";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "./assets/theme-dark";

// Material Dashboard 2 React routes
import routes from "./routes";

// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav } from "./context";

// Images
import brandWhite from "./assets/images/logo-skybrew.png";
import brandDark from "./assets/images/logo-skybrew.png";

import { setupAxiosInterceptors } from "./services/interceptor";
import ProtectedRoute from "./components/ProtectedRoute";

import Login from "./pages/Login";
import OrderDetails from "./pages/OrderDetails";
import CustomerOrders from "./pages/CustomerOrders";
import ItemsSummaryStaff from "./pages/ItemsSummaryStaff";

import { AuthContext } from "./context";

import { getFirebaseToken, onForegroundMessage } from "./firebase.js";

export default function App() {
  const authContext = useContext(AuthContext);

  const [openNotification, setOpenNotification] = useState(false);
  const toggleNotification = () => setOpenNotification(!openNotification);
  const [notificationColor, setNotificationColor] = useState("primary");
  const [notificationIcon, setNotificationIcon] = useState("notification");
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationContent, setNotificationContent] = useState("");

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    layout,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // if the token expired or other errors it logs out and goes to the login page
  const navigate = useNavigate();

  setupAxiosInterceptors(() => {
    authContext.logout();
    navigate("/login");
  });

  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [navigate]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && route.type !== "auth") {
        return (
          <Route
            exact
            path={route.route}
            element={
              <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }
      return null;
    });

  const handleGetFirebaseToken = () => {
    getFirebaseToken()
      .then((firebaseToken) => {
        console.log("Firebase token: ", firebaseToken);
      })
      .catch((err) =>
        console.error(
          "An error occured while retrieving firebase token. ",
          err,
        ),
      );
  };

  onForegroundMessage()
    .then((payload) => {
      console.log("Received foreground message:", payload);
      const {
        data: { title, body, icon, color },
      } = payload;

      setNotificationIcon(icon);
      setNotificationColor(color);

      setNotificationTitle(title);
      setNotificationContent(body);

      setOpenNotification(true);
    })
    .catch((err) =>
      console.log(
        "An error occured while retrieving foreground message. ",
        err,
      ),
    );

  useEffect(() => {
    if (authContext.isAuthenticated) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          handleGetFirebaseToken();
        } else {
          console.log("Unable to get permission to notify.");
        }
      });
    }
  }, [authContext.isAuthenticated]);

  return (
    <>
      {
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline />
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={
                  (transparentSidenav && !darkMode) || whiteSidenav
                    ? brandDark
                    : brandWhite
                }
                brandname="Skybrew"
                routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            </>
          )}
          <Routes>
            <Route path="/login" element={<Login />} />
            {getRoutes(routes)}
            <Route
              path="/items-summary"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  {<ItemsSummaryStaff />}
                </ProtectedRoute>
              }
              key="items-summary-staff"
            />
            <Route
              path="/orders/:orderId/:externalOrderId"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  {<OrderDetails />}
                </ProtectedRoute>
              }
              key="orders"
            />
            <Route
              path="/customers/:customerId"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  {<CustomerOrders />}
                </ProtectedRoute>
              }
              key="orders"
            />
            <Route
              path="/customers/:customerId/orders"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  {<CustomerOrders />}
                </ProtectedRoute>
              }
              key="orders"
            />
            <Route
              path="/report"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  {<Navigate to="/report/orders" />}
                </ProtectedRoute>
              }
            />
            <Route
              path="/*"
              element={
                <ProtectedRoute isAuthenticated={authContext.isAuthenticated}>
                  {<Navigate to="/dashboard" />}
                </ProtectedRoute>
              }
            />
          </Routes>
          <MDSnackbar
            open={openNotification}
            color={notificationColor}
            title={notificationTitle}
            content={notificationContent}
            icon={notificationIcon}
            close={toggleNotification}
          />
        </ThemeProvider>
      }
    </>
  );
}
