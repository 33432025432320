import { useState, useEffect } from "react";

// csv importer
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";

// @mui material components
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";

// @mui lab
import LoadingButton from "@mui/lab/LoadingButton";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React layout components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DataTable from "components/Tables/DataTable";
import Footer from "components/Footer";

// Data
import CustomerTableData from "pages/Customers/data/customersTableData";

// HTTP Service
import HttpService from "services/http-service";

const Customers = () => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState("info");

  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const handleOpenSpeedDial = () => setOpenSpeedDial(true);
  const handleCloseSpeedDial = () => setOpenSpeedDial(false);

  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [customerDetails, setCustomerDetails] = useState([]);

  const [openEditCustomer, setOpenEditCustomer] = useState(false);
  const handleOpenEditCustomer = () => setOpenEditCustomer(true);
  const handleCloseEditCustomer = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    setOpenEditCustomer(false);
    setLoadingButton(false);
  };

  const [openImportCustomers, setOpenImportCustomers] = useState(false);
  const handleOpenImportCustomers = () => {
    setOpenImportCustomers(true);
    setOpenSpeedDial(false);
  };
  const handleCloseImportCustomers = () => {
    setOpenImportCustomers(false);
  };

  const handleOpenLoading = () => setLoadingScreen(true);
  const handleCloseLoading = () => setLoadingScreen(false);

  const [inputs, setInputs] = useState({
    name: "",
    contact_number: "",
    email: "",
    address: "",
  });
  const [errors, setErrors] = useState({
    nameError: false,
    contactNumberError: false,
    emailError: false,
    addressError: false,
  });

  const changeHandler = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });

    // Reset error state when the user is typing
    setErrors({
      nameError: false,
      contactNumberError: false,
      emailError: false,
      addressError: false,
    });
  };

  const handleEditButtonClick = async (customerId) => {
    // Fetch for customer here then show backdrop loading.
    handleOpenLoading();
    try {
      const response = await HttpService.get(
        "/kitchen/customers/" + customerId,
      );

      const customerData = response.data || {};
      setCustomerDetails(customerData);

      setInputs({
        name: customerData.name,
        contact_number: customerData.contact_number,
        email: customerData.email,
        address: customerData.address,
      });

      handleOpenEditCustomer();
      handleCloseLoading();
    } catch (error) {
      console.log("Error fetching customer:", error);
    }
  };

  const validateInputs = () => {
    const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (
      inputs.email.trim().length === 0 ||
      !inputs.email.trim().match(mailFormat)
    ) {
      setErrors({ ...errors, emailError: true });
      return false;
    }

    if (inputs.name.trim().length < 3) {
      setErrors({ ...errors, nameError: true });
      return false;
    }

    if (inputs.contact_number.trim().length < 6) {
      setErrors({ ...errors, contactNumberError: true });
      return false;
    }

    if (inputs.address.trim().length < 6) {
      setErrors({ ...errors, addressError: true });
      return false;
    }

    return true;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setShowSnackbar(false);
  };

  const setSnackbar = (color, message, show) => {
    setShowSnackbar(show);
    setSnackbarColor(color);
    setSnackbarMessage(message);
  };

  const { columns, rows, fetchCustomers, modifyCustomer } = CustomerTableData({
    onEditButtonClicked: handleEditButtonClick,
  });
  const [customerNameFilter, setCustomerNameFilter] = useState("");

  const handleSubmitUpdateCustomer = async (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }

    // start the loading here.
    setLoadingButton(true);
    try {
      const customerId = e.target.getAttribute("data-customer-id");

      const response = await HttpService.put(
        "/kitchen/customers/" + customerId,
        {
          name: inputs.name,
          contact_number: inputs.contact_number,
          email: inputs.email,
          address: inputs.address,
        },
      );

      setLoadingButton(false);
      if (response.status === 201) {
        modifyCustomer(customerId, response.data);
        handleCloseEditCustomer();
      }
    } catch (error) {
      // error.details map here the set the error.
      console.log("Error updating customer:", error);
      setLoadingButton(false);
    }
  };

  const handleCustomerNameChange = (event) => {
    setCustomerNameFilter(event.target.value);
  };

  useEffect(() => {
    setLoadingData(true);
    // Initially fetch all data
    fetchCustomers().then(() => {
      setLoadingData(false);
    });
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();

    let fetchDataParams = {
      filters: {
        customer_name: customerNameFilter,
      },
    };

    try {
      setLoadingData(true);
      fetchCustomers(fetchDataParams).then(() => {
        setLoadingData(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3} sx={{ minHeight: "calc(100vh - 150px)" }}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                mb={3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="md"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white">
                  Customers
                </MDTypography>
              </MDBox>
              <MDBox
                component="form"
                role="form"
                method="POST"
                onSubmit={submitHandler}
              >
                <Grid container spacing={2} mb={2} mx={1}>
                  <Grid item xs={3}>
                    <MDInput
                      label="Customer Name"
                      placeholder="Customer Name"
                      fullWidth
                      onChange={handleCustomerNameChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <MDButton variant="contained" color="primary" type="submit">
                      Filter
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            <Card sx={{ marginTop: "10px" }}>
              <MDBox pt={3}>
                {!loadingData ? (
                  <DataTable
                    table={{ columns, rows }}
                    canSearch={false}
                    isSorted={true}
                    canSort={true}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder={false}
                  />
                ) : (
                  <MDBox
                    p={3}
                    textAlign="center"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <MDBox>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                      </Grid>
                      <Grid item xs={3}>
                        <MDBox>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                      </Grid>
                      <Grid item xs={3}>
                        <MDBox>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                      </Grid>
                      <Grid item xs={3}>
                        <MDBox>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12}>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingScreen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Dialog open={openEditCustomer} onClose={handleCloseEditCustomer}>
          <MDBox
            component="form"
            role="form"
            method="POST"
            onSubmit={handleSubmitUpdateCustomer}
            data-customer-id={customerDetails.id}
          >
            <DialogTitle>Edit Customer</DialogTitle>
            <DialogContent>
              <MDBox pt={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MDInput
                      variant="outlined"
                      name="name"
                      fullWidth
                      label="Fullname"
                      placeholder="Fullname"
                      value={inputs.name}
                      onChange={changeHandler}
                      error={errors.nameError}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MDInput
                      variant="outlined"
                      name="contact_number"
                      fullWidth
                      label="Contact Number"
                      placeholder="Contact Number"
                      value={inputs.contact_number}
                      onChange={changeHandler}
                      error={errors.contactNumberError}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <MDInput
                      variant="outlined"
                      name="email"
                      fullWidth
                      label="Email Address"
                      placeholder="Email Address"
                      value={inputs.email}
                      onChange={changeHandler}
                      error={errors.emailError}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MDInput
                      multiline
                      rows={2}
                      name="address"
                      fullWidth
                      label="Address"
                      placeholder="Address"
                      value={inputs.address}
                      onChange={changeHandler}
                      error={errors.addressError}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </DialogContent>
            <DialogActions>
              <MDButton
                onClick={handleCloseEditCustomer}
                color="secondary"
                variant="text"
              >
                Close
              </MDButton>
              <MDButton
                color="primary"
                variant="contained"
                type="submit"
                component={LoadingButton}
                loading={loadingButton}
              >
                <span>Save changes</span>
              </MDButton>
            </DialogActions>
          </MDBox>
        </Dialog>
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          open={openImportCustomers}
          onClose={handleCloseImportCustomers}
        >
          <DialogTitle>Import Customers</DialogTitle>
          <DialogContent>
            <MDBox>
              <Importer
                dataHandler={async (rows, { startIndex }) => {
                  for (let i = startIndex; i < rows.length; i++) {
                    const row = rows[i];
                    try {
                      await HttpService.post("/kitchen/customers", {
                        name: row.name,
                        contact_number: row.contact_number,
                        email: row.email,
                        address: row.address,
                      });
                    } catch (error) {
                      console.error("Error during dataHandler:", error);
                    }
                  }
                }}
                defaultNoHeader={false}
                restartable={true}
                onComplete={() => {
                  setSnackbar("info", "Importing contacts completed.", true);
                }}
                onClose={() => {
                  fetchCustomers();
                  handleCloseImportCustomers();
                }}
              >
                <ImporterField name="name" label="Name" />
                <ImporterField name="contact_number" label="Contact Number" />
                <ImporterField name="email" label="Email" />
                <ImporterField name="address" label="Address" />
              </Importer>
            </MDBox>
          </DialogContent>
        </Dialog>
      </MDBox>
      <MDSnackbar
        color={snackbarColor}
        icon="notifications"
        title="Skybrew"
        content={snackbarMessage}
        dateTime=""
        open={showSnackbar}
        autoHideDuration={3000}
        close={handleCloseSnackbar}
      />
      <SpeedDial
        ariaLabel="Order Details"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={
          <Icon fontSize="small" color="inherit">
            add
          </Icon>
        }
        onClose={handleCloseSpeedDial}
        onOpen={handleOpenSpeedDial}
        open={openSpeedDial}
      >
        <SpeedDialAction
          key="import"
          icon={
            <ImportContactsIcon fontSize="small" color="inherit">
              importContacts
            </ImportContactsIcon>
          }
          tooltipTitle="Import Customers from CSV"
          onClick={() => handleOpenImportCustomers()}
        />
      </SpeedDial>
      <Footer />
    </DashboardLayout>
  );
};

export default Customers;
