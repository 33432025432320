import { useState, useEffect } from "react";

import { styled } from "@mui/material/styles";

// prop types
import PropTypes from "prop-types";

// @mui
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Skeleton from "@mui/material/Skeleton";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";

// Material Design components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Lightbox
import Lightbox from "yet-another-react-lightbox";
import Download from "yet-another-react-lightbox/plugins/download";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

// useLongPress
import { useLongPress } from "@uidotdev/usehooks";

import HttpService from "services/http-service";

const OrderPhotos = ({ orderID, orderPhotos }) => {
  const [localOrderPhotos, setLocalOrderPhotos] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [lightboxPhotos, setLightboxPhotos] = useState(0);
  const [openLightbox, setOpenLightbox] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isDeleteMode, setIsDeleteMode] = useState(false);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [isDeleteConfirmationLoading, setDeleteConfirmationLoading] =
    useState(false);

  const handleCloseDeleteConfirmation = (event, reason) => {
    if (reason && reason == "backdropClick") return;

    setOpenDeleteConfirmation(false);
    setIsDeleteMode(false);
    setSelectedPhotos([]);
  };

  const handleFileChange = async (event) => {
    const files = event.target.files;
    if (files.length) {
      try {
        await uploadFiles(files);
      } catch (error) {
        console.error("Error uploading files:", error);
      }
    }
  };

  const handleImageClick = (photoID) => {
    if (selectedPhotos.includes(photoID)) {
      setSelectedPhotos((prevSelectedPhotos) =>
        prevSelectedPhotos.filter((item) => item !== photoID),
      );
    } else {
      setSelectedPhotos((prevSelectedPhotos) => [
        ...prevSelectedPhotos,
        photoID,
      ]);
    }
  };

  const handleOpenLightbox = (index) => {
    setPhotoIndex(index);
    setOpenLightbox(true);
  };

  const handleCancelDeleteMode = () => {
    setIsDeleteMode(false);
    setSelectedPhotos([]);
  };

  const attrs = useLongPress(
    (event) => {
      if (!isDeleteMode) {
        const photoID = parseInt(event.target.getAttribute("data-photo-id"));
        handleImageClick(photoID);
      }
      setIsDeleteMode(true);
    },
    {
      onCancel: (event) => {
        if (isDeleteMode === false) {
          const index = event.target.getAttribute("data-index");
          if (index) {
            handleOpenLightbox(index);
          }
        } else {
          // add the photo for selected
          const photoID = parseInt(event.target.getAttribute("data-photo-id"));
          handleImageClick(photoID);
        }
      },
      threshold: 1000,
    },
  );

  const disableContextMenu = (event) => {
    event.preventDefault();
  };

  const prepareImageList = (orderPhotos) => {
    let photos = [];
    if (orderPhotos.length != 0) {
      orderPhotos.forEach((orderPhoto) => {
        if (orderPhoto.status == 1) {
          photos.push({
            src: orderPhoto.url,
            alt: orderPhoto.order_id + " / " + orderPhoto.id,
            width: orderPhoto.width,
            height: orderPhoto.height,
            download: orderPhoto.url,
            imageFit: "contain",
          });
        }
      });
    }
    return photos;
  };

  useEffect(
    function () {
      setLocalOrderPhotos(orderPhotos);
    },
    [orderPhotos],
  );

  useEffect(
    function () {
      let photos = prepareImageList(localOrderPhotos);
      setLightboxPhotos(photos);
    },
    [localOrderPhotos],
  );

  const requestDeleteFiles = async () => {
    setDeleteConfirmationLoading(true);
    const response = await HttpService.delete(
      "/kitchen/orders/" + orderID + "/photos",
      { photos: selectedPhotos },
    );

    if (response.status === 200) {
      // now delete the data in order photos
      setDeleteConfirmationLoading(false);
      setOpenDeleteConfirmation(false);
      setIsDeleteMode(false);
      setSelectedPhotos([]);
      const updatedOrderPhotos = localOrderPhotos.filter((orderPhoto) => {
        return !selectedPhotos.includes(orderPhoto.id);
      });

      const updatedUploadFiles = uploadedFiles.filter((orderPhoto) => {
        return !selectedPhotos.includes(orderPhoto);
      });

      setLocalOrderPhotos(updatedOrderPhotos);
      setUploadedFiles(updatedUploadFiles);
    }
  };

  const uploadFiles = async (files) => {
    const promises = Array.from(files).map((file) => {
      const formData = new FormData();
      formData.append("file", file);
      return HttpService.upload(
        "/kitchen/orders/" + orderID + "/photos",
        formData,
      ).then((response) => {
        if (response.data.status === 1) {
          setUploadedFiles((prevUploadedFiles) => [
            ...prevUploadedFiles,
            response.data.id,
          ]);
        }
      });
    });

    try {
      await Promise.all(promises);
      console.log("All files uploaded successfully.");
    } catch (error) {
      console.error("One or more file uploads failed:", error);
    }
  };

  const renderUploadedPhotos = () => {
    return uploadedFiles.map((uploadedFile) => (
      <ImageListItem
        key={`order-photo-${uploadedFile}`}
        {...attrs}
        sx={{ cursor: "pointer" }}
      >
        <Skeleton
          variant="rectangular"
          width="100%"
          height={158}
          data-photo-id={uploadedFile}
          onContextMenu={disableContextMenu}
          style={{
            width: "100%",
            border:
              isDeleteMode && selectedPhotos.includes(uploadedFile)
                ? "1px solid #e91e63"
                : "none",
            opacity:
              isDeleteMode && !selectedPhotos.includes(uploadedFile) ? 0.5 : 1,
          }}
        />
      </ImageListItem>
    ));
  };

  const renderOrderPhotos = () => {
    const orderUploadedImages = [];
    localOrderPhotos.forEach((photo, index) => {
      if (photo.status === 0) {
        // If status is 0, render a Skeleton
        orderUploadedImages.push(
          <ImageListItem
            key={`order-photo-${photo.id}`}
            sx={{
              cursor: "pointer",
            }}
            {...attrs}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height={158}
              data-photo-id={photo.id}
              onContextMenu={disableContextMenu}
              style={{
                width: "100%",
                border:
                  isDeleteMode && selectedPhotos.includes(photo.id)
                    ? "1px solid #e91e63"
                    : "none",
                opacity:
                  isDeleteMode && !selectedPhotos.includes(photo.id) ? 0.5 : 1,
              }}
            />
          </ImageListItem>,
        );
      } else {
        // Otherwise, render the image
        orderUploadedImages.push(
          <ImageListItem
            key={`order-photo-${photo.id}`}
            sx={{
              cursor: "pointer",
            }}
            {...attrs}
          >
            <img
              src={photo.thumbnail_url}
              alt={`#${index} - ${selectedPhotos.includes(photo.id) ? "Selected" : "Not Selected"}`}
              data-index={index}
              data-photo-id={photo.id}
              onContextMenu={disableContextMenu}
              style={{
                width: "100%",
                border:
                  isDeleteMode && selectedPhotos.includes(photo.id)
                    ? "1px solid #e91e63"
                    : "none",
                opacity:
                  isDeleteMode && !selectedPhotos.includes(photo.id) ? 0.5 : 1,
              }}
            />
          </ImageListItem>,
        );
      }
    });

    return orderUploadedImages;
  };

  const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;

  return (
    <>
      <Dialog
        open={openDeleteConfirmation}
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          Delete {selectedPhotos.length} Photos
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete {selectedPhotos.length} photos? Once
            deleted they cannot be recovered.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleCloseDeleteConfirmation}>Cancel</MDButton>
          <MDButton
            disabled={isDeleteConfirmationLoading}
            onClick={() => requestDeleteFiles()}
            color="error"
          >
            {isDeleteConfirmationLoading && (
              <CircularProgress
                color="warning"
                size={20}
                sx={{ marginRight: "5px" }}
              />
            )}
            Yes! Delete
          </MDButton>
        </DialogActions>
      </Dialog>
      <Lightbox
        open={openLightbox}
        close={() => setOpenLightbox(false)}
        index={photoIndex}
        slides={lightboxPhotos}
        plugins={[Zoom, Download]}
      />
      <MDBox mb={4}>
        <Card>
          <MDBox mx={{ xs: 3, md: 4 }} mb={4}>
            <MDBox py={2}>
              <MDTypography variant="h5" textTransform="capitalize">
                Photos
              </MDTypography>
            </MDBox>
            <MDBox mx={{ xs: 0, md: 2 }}>
              <MDBox mb={3}>
                {isDeleteMode ? (
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <MDButton
                        fullWidth
                        color="info"
                        onClick={() => handleCancelDeleteMode()}
                      >
                        Cancel
                      </MDButton>
                    </Grid>
                    <Grid item xs={6}>
                      <MDButton
                        fullWidth
                        color="error"
                        disabled={selectedPhotos.length === 0}
                        onClick={() => setOpenDeleteConfirmation(true)}
                      >
                        Delete ({selectedPhotos.length})
                      </MDButton>
                    </Grid>
                  </Grid>
                ) : (
                  <MDButton
                    fullWidth
                    component="label"
                    startIcon={<CloudUploadIcon />}
                    variant="contained"
                    color="success"
                  >
                    Add a photo
                    <VisuallyHiddenInput
                      type="file"
                      accept="image/*"
                      capture="environment"
                      multiple
                      onChange={handleFileChange}
                    />
                  </MDButton>
                )}
              </MDBox>
              <MDBox>
                <ImageList
                  sx={{ width: "100%", height: "300" }}
                  variant="quilted"
                  cols={3}
                  gap={8}
                  rowHeight={160}
                >
                  {renderOrderPhotos()}
                  {renderUploadedPhotos()}
                </ImageList>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </MDBox>
    </>
  );
};

OrderPhotos.propTypes = {
  orderID: PropTypes.number.isRequired,
  orderPhotos: PropTypes.array,
};

export default OrderPhotos;
