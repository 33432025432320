import React, { forwardRef, useEffect } from "react";

import PropTypes from "prop-types";

// DateJS
import dayjs from "dayjs";

// @mui material components
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const PrintableOrders = forwardRef(({ orders, onRender }, ref) => {
  useEffect(() => {
    // Call the onRender function to notify the parent component
    onRender(true);
  }, [onRender]);

  return (
    <div ref={ref} className="printable-content">
      {orders.map((orderDetails) => (
        <MDBox
          key={orderDetails.id}
          className="page-break"
          sx={{ display: { xs: "block", displayPrint: "block" } }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDTypography variant="h3">
                #{orderDetails.external_order_id} / {orderDetails.customer_name}
              </MDTypography>
            </Grid>
            <Grid item xs={12}>
              {orderDetails.notes ? (
                <MDTypography variant="body2">
                  Notes : {orderDetails.notes}
                </MDTypography>
              ) : null}
            </Grid>
            <Grid item xs={4}>
              Delivery Date : {orderDetails.delivery_date}
            </Grid>
            <Grid item xs={4}>
              Dispatch Time : {dayjs(`1970-01-01 ${orderDetails.dispatch_time}`).format("hh:mm A")}
            </Grid>
            <Grid item xs={4}>
              Delivery ETA : {dayjs(`1970-01-01 ${orderDetails.delivery_eta}`).format("hh:mm A")}
            </Grid>
            <Grid item xs={12}>
              <TableContainer sx={{ boxShadow: "none", borderRadius: 0 }}>
                <Table aria-label="order lines table">
                  <TableBody>
                    {orderDetails.order_line_items.map((orderLineItem, index) => (
                      <React.Fragment key={`${orderLineItem.id}-${index}-item`}>
                        {orderLineItem.subitems.length === 0 &&
                        !orderLineItem.item_description.toLowerCase().includes("upgrade") ? (
                            <TableRow key={`${orderLineItem.id}-${index}-xitem`}>
                              <TableCell scope="row">
                                <MDTypography variant="body2">
                                  {orderLineItem.item_quantity} x {orderLineItem.item_description}
                                </MDTypography>
                              </TableCell>
                            </TableRow>
                          ) : null}

                        {orderLineItem.subitems.length !== 0 &&
                          orderLineItem.subitems.map((subitem, subIndex) => (
                            <TableRow key={`${subitem.id}-${subIndex}-subitem`}>
                              <TableCell scope="row">
                                <MDTypography variant="body2">
                                  {subitem.item_quantity} x {subitem.item_description}
                                </MDTypography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </MDBox>
      ))}
    </div>
  );
});

PrintableOrders.propTypes = {
  orders: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      external_order_id: PropTypes.number.isRequired,
      customer_name: PropTypes.string.isRequired,
      notes: PropTypes.string,
      delivery_date: PropTypes.string.isRequired,
      dispatch_time: PropTypes.string.isRequired,
      delivery_eta: PropTypes.string.isRequired,
      order_line_items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          item_quantity: PropTypes.number.isRequired,
          item_description: PropTypes.string.isRequired,
          subitems: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              item_quantity: PropTypes.number.isRequired,
              item_description: PropTypes.string.isRequired,
            })
          ),
        })
      ),
    })
  ).isRequired,
  onRender: PropTypes.func.isRequired,
};

PrintableOrders.displayName = "PrintableOrders";

export default PrintableOrders;
