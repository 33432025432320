import { useState, useEffect, useContext } from "react";

// react-router components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useMediaQuery from "@mui/material/useMediaQuery";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React example components
import Breadcrumbs from "components/Breadcrumbs";

import AuthService from "services/auth-service";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarMobileMenu,
} from "components/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
} from "context";

import { AuthContext } from "context";

function DashboardNavbar({ absolute, light, breadCrumbRoutes }) {
  const authContext = useContext(AuthContext);
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const route = breadCrumbRoutes || useLocation().pathname.split("/").slice(1);

  const [userMenu, setUserMenu] = useState(null);

  const openUserMenu = ({ currentTarget }) => setUserMenu(currentTarget);
  const closeUserMenu = () => setUserMenu(null);

  const suName = localStorage.getItem("suname");

  const stringToColor = (string) => {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const stringAvatar = (name) => {
    const words = name.split(" ");
    let initials = "";

    if (words.length > 1) {
      initials = `${words[0][0]}${words[words.length - 1][0]}`;
    } else {
      initials = name[0];
    }

    return {
      sx: {
        bgcolor: stringToColor(name),
        cursor: "pointer",
      },
      children: initials,
    };
  }

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const handleLogOut = async () => {
    authContext.logout();
    AuthService.logout();
  };

  const xlScreen = useMediaQuery("(min-width: 1200px)");

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={{ display: { xl: "flex", xs: "none" }, alignItems: "center", justifyContent: "stretch", width: "max-content" }}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        <MDBox sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between", width: { xl: "max-content", xs: "100%"} }}>
          <MDAvatar {...stringAvatar(suName)} variant="circle" size="lg" shadow="xxl" onClick={openUserMenu} />
          <Menu
            id="user-menu"
            anchorEl={userMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: xlScreen ? "right" : "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: xlScreen ? "right" : "left",
            }}
            open={Boolean(userMenu)}
            onClose={closeUserMenu}
          >
            <MenuItem onClick={handleLogOut}>Logout</MenuItem>
          </Menu>
          <IconButton
            size="small"
            disableRipple
            color="inherit"
            sx={navbarMobileMenu}
            onClick={handleMiniSidenav}
          >
            <Icon sx={iconsStyle} fontSize="large">
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  breadCrumbRoutes: PropTypes.any
};

export default DashboardNavbar;
