import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import HttpService from "services/http-service";

const firebaseConfig = {
  apiKey: "AIzaSyD48Im3uL4voSRX7ogU5tqnKpSlc2bcR64",
  authDomain: "skybrew-app.firebaseapp.com",
  projectId: "skybrew-app",
  storageBucket: "skybrew-app.appspot.com",
  messagingSenderId: "105427230007",
  appId: "1:105427230007:web:40c148c7650232b6ee8eb2",
  measurementId: "G-5J3QDKX1RZ"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getFirebaseToken = () =>
  getToken(messaging, {
    vapidKey: "BLS72pCBNCioqizxz-JnksaawJJkeGKBS-XEsD0iePQ_XMoBI3dU68bGg8-uLN2I_MKYMCVt-02wIyCrJGoSh0U"
  })
    .then((fcmToken) => {
      const postData = {
        token: fcmToken
      };

      return HttpService.post("/kitchen/fcm-token", postData)
        .then(() => {
          return fcmToken;
        })
        .catch((error) => {
          console.error("Error posting FCM token:", error);

          return fcmToken;
        });
    })

export const onForegroundMessage = () => {
  return new Promise((resolve) => {
    // Define the function to handle incoming messages
    const handleMessage = (payload) => {
      // Resolve the promise with the payload
      resolve(payload);
    };

    // Attach the onMessage listener
    const unsubscribe = onMessage(messaging, handleMessage);

    // Return a function to detach the listener when needed
    return () => unsubscribe();
  });
};
