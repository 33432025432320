import PropTypes from "prop-types";

import Link from "@mui/material/Link";

const GoogleMapsLink = ({ address, children }) => {
  const encodedAddress = encodeURIComponent(address);
  const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;

  return (
    <Link href={mapsUrl} target="_blank" rel="noopener noreferrer">
      {children || `Open in Google Maps: ${address}`}
    </Link>
  );
};

GoogleMapsLink.propTypes = {
  address: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export default GoogleMapsLink;
