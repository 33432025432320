import PropTypes from "prop-types";

import Chip from "@mui/material/Chip";

const RiderStatus = ({ status }) => {
  const getStatusInfo = () => {
    if (status === 1) {
      return { chipColor: "primary", chipLabel: "Active" };
    } else if (status === 0) {
      return { chipColor: "default", chipLabel: "Inactive" };
    } else if (status === 3) {
      return { chipcolor: "warning", chipLabel: "Pending" };
    } else {
      return { chipColor: "warning", chipLabel: "Unknown" };
    }
  };

  const { chipColor, chipLabel } = getStatusInfo();

  return <Chip color={chipColor} label={chipLabel} size="small" />;
};

// Typechecking props for the Users
RiderStatus.propTypes = {
  status: PropTypes.number.isRequired,
};

export default RiderStatus;
