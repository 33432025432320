// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DefaultNavbar from "components/Navbars/DefaultNavbar";
import PageLayout from "components/LayoutContainers/PageLayout";

function StaffLayout ({ children }) {
  return (
    <PageLayout>
      <DefaultNavbar light={false} />
      <MDBox
        mt={15}
        mx={3}
        sx={{ minHeight: 393 }}
        width="calc(100% - 48px)"
        display="flex"
        alignItems="normal"
        justifyContent="center"
      >
        {children}
      </MDBox>
    </PageLayout>
  );
}

// Typechecking props for the StaffLayout
StaffLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StaffLayout;
