import HttpService from "./http-service";

class AuthService {
  authEndpoint = process.env.REACT_APP_API_URL;

  login = async (payload) => {
    const loginEndpoint = "kitchen/login";
    return await HttpService.post(loginEndpoint, payload);
  };

  logout = async () => {
    const logoutEndpoint = "kitchen/logout";
    return await HttpService.get(logoutEndpoint);
  };
}

export default new AuthService();
