import { useState, forwardRef, useImperativeHandle } from "react";

import PropTypes from "prop-types";

// @mui material components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// HTTP Service
import HttpService from "services/http-service";

const ChangePassword = forwardRef(({ onSuccessChangedPassword }, ref) => {
  const [userId, setUserId] = useState(null);
  const [open, setOpen] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [inputs, setInputs] = useState({
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    password: false,
    confirmPassword: false,
  });

  const [errorMessages, setErrorMessages] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));

    setErrorMessages((prevMessages) => ({
      ...prevMessages,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleOpenChangePassword = () => {
    setOpen(true);
  };

  const handleCloseChangePassword = () => {
    setOpen(false);
  };

  const openChangePasswordDialog = (userId) => {
    setUserId(userId);
    setInputs({
      password: "",
      confirmPassword: "",
    })
    handleOpenChangePassword();
  };

  // Expose the openEditDialog function via ref
  useImperativeHandle(ref, () => ({
    openChangePasswordDialog,
  }));

  const validateInputs = () => {
    let hasErrors = false;

    if (inputs.password.trim().length < 8) {
      setErrors((prevErrors) => ({ ...prevErrors, password: true }));
      setErrorMessages((prevMessages) => ({ ...prevMessages, password: "Password must be at least 8 characters" }));
      hasErrors = true;
    }

    if (inputs.confirmPassword.trim().length < 8) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: true }));
      setErrorMessages((prevMessages) => ({ ...prevMessages, confirmPassword: "Password must be at least 8 characters" }));
      hasErrors = true;
    }

    if (inputs.password !== inputs.confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: true,
        confirmPassword: true,
      }));
      setErrorMessages((prevMessages) => ({ ...prevMessages, password: "Passwords do not match" }));
      hasErrors = true;
    }

    return !hasErrors;
  };

  const handleChangePasswordFormSubmit = async (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }

    let postData = {
      password: inputs.password
    };


    setLoadingButton(true);
    try {
      const response = await HttpService.post("/kitchen/users/" + userId + "/change-password", postData);

      if (response.status == "201") {
        onSuccessChangedPassword();
      }

      setLoadingButton(false);
      handleCloseChangePassword();
    } catch (e) {
      setLoadingButton(false);
      console.log("error changing password.");
    }
  };

  return (
    <MDBox sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>
      <Dialog
        open={open}
        onClose={handleCloseChangePassword}
        fullWidth={true}
        maxWidth="sm"
      >
        <MDBox component="form" onSubmit={handleChangePasswordFormSubmit}>
          <DialogTitle>Change Password</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} pt={1}>
              <Grid item xs={12} md={12}>
                <MDInput
                  name="password"
                  label="Password"
                  fullWidth
                  type="password"
                  onChange={handleFieldChange}
                  value={inputs.password}
                  error={errors.password}
                  helperText={errorMessages.password}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <MDInput
                  name="confirmPassword"
                  label="Confirm Password"
                  fullWidth
                  type="password"
                  onChange={handleFieldChange}
                  value={inputs.confirmPassword}
                  error={errors.confirmPassword}
                  helperText={errorMessages.confirmPassword}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <MDButton onClick={handleCloseChangePassword} color="secondary" variant="text">Close</MDButton>
            {
              loadingButton ? (
                <CircularProgress />
              ) : (
                <MDButton color="primary" type="submit">Save changes</MDButton>
              )
            }
          </DialogActions>
        </MDBox>
      </Dialog>
    </MDBox>
  );
});

ChangePassword.propTypes = {
  onSuccessChangedPassword: PropTypes.func,
};

ChangePassword.defaultProps = {
  onSuccessChangedPassword: () => {
  },
};

ChangePassword.displayName = "ChangePassword";

export default ChangePassword;