import { useState, forwardRef, useImperativeHandle } from "react";

import PropTypes from "prop-types";

// @mui component
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

// Material Design component
import MDButton from "components/MDButton";

// HTTP Service
import HttpService from "services/http-service";

const DeleteRiderDialog = forwardRef(({ onSuccessDeleted }, ref) => {
  const [riderID, setRiderID] = useState(null);
  const [open, setOpenDialog] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const handleCloseConfirmation = (e, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpenDialog(false);
  };

  const openDialog = async (riderID) => {
    setRiderID(riderID);
    setOpenDialog(true);
  };

  // Expose the openEditDialog function via ref
  useImperativeHandle(ref, () => ({
    openDialog,
  }));

  const requestDeleteRider = async () => {
    setLoadingButton(true);
    const response = await HttpService.delete("/kitchen/riders/" + riderID);

    if (response.status === 200) {
      onSuccessDeleted(riderID);
    }
    setOpenDialog(false);
    setLoadingButton(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseConfirmation}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">Rider</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete the rider? Once deleted they cannot be
          recovered.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleCloseConfirmation}>Cancel</MDButton>
        <MDButton
          disabled={loadingButton}
          onClick={() => requestDeleteRider()}
          color="error"
        >
          {loadingButton && (
            <CircularProgress
              color="warning"
              size={20}
              sx={{ marginRight: "5px" }}
            />
          )}
          Yes! Delete
        </MDButton>
      </DialogActions>
    </Dialog>
  );
});

DeleteRiderDialog.propTypes = {
  onSuccessDeleted: PropTypes.func,
};

DeleteRiderDialog.defaultProps = {
  onSuccessDeleted: () => {},
};

DeleteRiderDialog.displayName = "DeleteRiderDialog";

export default DeleteRiderDialog;
