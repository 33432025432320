import { useContext } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DefaultNavbarLink from "components/Navbars/DefaultNavbar/DefaultNavbarLink";
import AuthService from "services/auth-service";
import { AuthContext } from "context";

function DefaultNavbarMobile({ open, close }) {
  const authContext = useContext(AuthContext);
  const { width } = open && open.getBoundingClientRect();

  const handleLogOut = async () => {
    authContext.logout();
    AuthService.logout();
  };


  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      {!authContext.isAuthenticated && (
        <MDBox px={0.5}>
          <DefaultNavbarLink icon="key" name="login" route="/login" />
        </MDBox>
      )}
      {authContext.isAuthenticated && (
        <MDBox px={0.5}>
          <DefaultNavbarLink icon="dashboard" name="Orders" route="/dashboard" light={false} />
          <DefaultNavbarLink icon="listalt" name="Items Summary" route="/items-summary" light={false} />
          <DefaultNavbarLink
            icon="logout"
            name="logout"
            route="/logout"
            onClick={handleLogOut}
            light={false}
          />
        </MDBox>
      )}
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
