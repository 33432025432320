import { useState, forwardRef, useImperativeHandle } from "react";

import PropTypes from "prop-types";

// DateJS
import dayjs from "dayjs";

// @mui material components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

// @mui x
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateField } from "@mui/x-date-pickers/DateField";
import { TimeField } from "@mui/x-date-pickers/TimeField";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// HTTP Service
import HttpService from "services/http-service";

const EditOrder = forwardRef(({ onSuccessUpdate }, ref) => {
  const [orderID, setOrderID] = useState(null);
  const [open, setOpen] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [inputs, setInputs] = useState({
    delivery_date: new Date(),
    dispatch_time: new Date(),
    delivery_eta: new Date(),
    shipping_address: "",
    shipping_contact_person: "",
    shipping_contact_address: "",
    notes: "",
  });

  const [errors, setErrors] = useState({
    delivery_date: false,
    dispatch_time: false,
    delivery_eta: false,
    shipping_address: false,
    shipping_contact_person: false,
    shipping_contact_address: false,
    notes: false,
  });

  const [errorMessages, setErrorMessages] = useState({
    delivery_date: "",
    dispatch_time: "",
    delivery_eta: "",
    shipping_address: "",
    shipping_contact_person: "",
    shipping_contact_address: "",
    notes: "",
  });

  const setDeliveryDate = (value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      delivery_date: value,
    }));
  };

  const setDispatchTime = (value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      dispatch_time: value,
    }));
  }

  const setDeliveryEta = (value) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      delivery_eta: value,
    }));
  }

  const handleOpenUpdateOrder = () => {
    setOpen(true);
  }

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));

    setErrorMessages((prevMessages) => ({
      ...prevMessages,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleCloseUpdateOrder = () => {
    setOpen(false);
  };

  const openEditDialog = async (orderID) => {
    setLoadingScreen(true);
    try {
      const response = await HttpService.get("/kitchen/orders/" + orderID);
      setOrderID(orderID);
      setInputs({
        delivery_date: dayjs(response.data.delivery_date),
        dispatch_time: dayjs("1970-01-01 " + response.data.dispatch_time),
        delivery_eta: dayjs("1970-01-01 " + response.data.delivery_eta),
        shipping_address: response.data.shipping_address ? response.data.shipping_address : response.data.customer_address,
        shipping_contact_person: response.data.shipping_contact_person ? response.data.shipping_contact_person : response.data.customer_name,
        shipping_contact_number: response.data.shipping_contact_number ? response.data.shipping_contact_number : response.data.customer_contact_number,
        notes: response.data.notes,
      });

      setErrors({
        delivery_date: false,
        dispatch_time: false,
        delivery_eta: false,
        shipping_address: false,
        shipping_contact_person: false,
        shipping_contact_address: false,
        notes: false,
      });

      setErrorMessages({
        delivery_date: "",
        dispatch_time: "",
        delivery_eta: "",
        shipping_address: "",
        shipping_contact_person: "",
        shipping_contact_address: "",
        notes: "",
      });

      setLoadingScreen(false);
      handleOpenUpdateOrder();
    } catch (e) {
      console.log(e);
      setLoadingScreen(false);
    }
  };

  // Expose the openEditDialog function via ref
  useImperativeHandle(ref, () => ({
    openEditDialog,
  }));

  const validateInputs = () => {
    let hasErrors = false;

    if (inputs.shipping_address.trim().length === 0) {
      setErrors((prevErrors) => ({ ...prevErrors, shipping_address: true }));
      setErrorMessages((prevMessages) => ({ ...prevMessages, shipping_address: "Invalid shipping address" }));
      hasErrors = true;
    }

    if (inputs.shipping_contact_number.trim().length === 0) {
      setErrors((prevErrors) => ({ ...prevErrors, shipping_contact_number: true }));
      setErrorMessages((prevMessages) => ({ ...prevMessages, shipping_contact_number: "Invalid shipping address" }));
      hasErrors = true;
    }

    if (inputs.shipping_contact_person.trim().length < 6) {
      setErrors((prevErrors) => ({ ...prevErrors, shipping_contact_person: true }));
      setErrorMessages((prevMessages) => ({ ...prevMessages, shipping_contact_person: "Invalid name. Must be at least 6 characters" }));
      hasErrors = true;
    }

    return !hasErrors;
  };

  const requestUpdateOrder = async () => {
    setLoadingButton(true);

    try {
      let orderData = {
        delivery_date: inputs.delivery_date.format("YYYY-MM-DD"),
        dispatch_time: inputs.dispatch_time.format("HH:mm:ss"),
        delivery_eta: inputs.delivery_eta.format("HH:mm:ss"),
        shipping_address: inputs.shipping_address,
        shipping_contact_person: inputs.shipping_contact_person,
        shipping_contact_address: inputs.shipping_contact_address,
        notes: inputs.notes,
      };

      const response = await HttpService.put("/kitchen/orders/" + orderID, orderData)

      if (response.status === 201) {
        setErrors({
          delivery_date: false,
          dispatch_time: false,
          delivery_eta: false,
          shipping_address: false,
          shipping_contact_person: false,
          shipping_contact_address: false,
          notes: false,
        });

        setErrorMessages({
          delivery_date: "",
          dispatch_time: "",
          delivery_eta: "",
          shipping_address: "",
          shipping_contact_person: "",
          shipping_contact_address: "",
          notes: "",
        });

        handleCloseUpdateOrder();
        onSuccessUpdate(orderID, orderData);
      }

      setLoadingButton(false);
    } catch (e) {
      setLoadingButton(false);
    }
  };

  const handleUpdateOrderFormSubmit = (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }

    setLoadingButton(true);
    requestUpdateOrder();
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingScreen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>
        <Dialog
          open={open}
          onClose={handleCloseUpdateOrder}
          fullWidth={true}
          maxWidth="sm"
        >
          <MDBox component="form" onSubmit={handleUpdateOrderFormSubmit}>
            <DialogTitle>Update Order</DialogTitle>
            <DialogContent>
              <Grid container spacing={3} pt={1}>
                <Grid item xs={6} md={6}>
                  <MDInput
                    name="shipping_contact_person"
                    label="Shipping Contact Person"
                    fullWidth
                    onChange={handleFieldChange}
                    value={inputs.shipping_contact_person}
                    error={errors.shipping_contact_person}
                    helperText={errorMessages.shipping_contact_person}
                  />
                </Grid>
                <Grid item xs={6} md={6}>
                  <MDInput
                    name="shipping_contact_number"
                    label="Shipping Contact Number"
                    fullWidth
                    onChange={handleFieldChange}
                    value={inputs.shipping_contact_number}
                    error={errors.shipping_contact_number}
                    helperText={errorMessages.shipping_contact_number}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <MDInput
                    multiline rows={2}
                    name="shipping_address"
                    label="Shipping Address"
                    fullWidth
                    onChange={handleFieldChange}
                    value={inputs.shipping_address}
                    error={errors.shipping_address}
                    helperText={errorMessages.shipping_address}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      fullWidth
                      label="Delivery Date"
                      value={inputs.delivery_date}
                      onChange={(newValue) => setDeliveryDate(newValue)}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimeField
                      fullWidth
                      label="Dispatch Time"
                      value={inputs.dispatch_time}
                      onChange={(newValue) => setDispatchTime(newValue)}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={4}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <TimeField
                      fullWidth
                      label="Delivery ETA"
                      value={inputs.delivery_eta}
                      onChange={(newValue) => setDeliveryEta(newValue)}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} md={12}>
                  <MDInput
                    multiline rows={5}
                    name="notes"
                    label="Notes"
                    fullWidth
                    onChange={handleFieldChange}
                    value={inputs.notes}
                    error={errors.notes}
                    helperText={errorMessages.notes}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleCloseUpdateOrder} color="secondary" variant="text">Close</MDButton>
              {
                loadingButton ? (
                  <CircularProgress />
                ) : (
                  <MDButton color="primary" type="submit">Save changes</MDButton>
                )
              }
            </DialogActions>
          </MDBox>
        </Dialog>
      </MDBox>
    </>
  );
});

EditOrder.propTypes = {
  onSuccessUpdate: PropTypes.func,
};

EditOrder.defaultProps = {
  onSuccessUpdate: () => {
  },
};

EditOrder.displayName = "EditOrder";

export default EditOrder;
