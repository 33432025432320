// Material Dashboard 2 React Base Styles
import colors from "assets/theme/base/colors";

const { info, dark } = colors;

const globals = {
  html: {
    scrollBehavior: "smooth",
    overflowX: "hidden",
  },
  "*, *::before, *::after": {
    margin: 0,
    padding: 0,
  },
  "a, a:link, a:visited": {
    textDecoration: "none !important",
  },
  "a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited": {
    color: `${dark.main} !important`,
    transition: "color 150ms ease-in !important",
  },
  "a.link:hover, .link:hover, a.link:focus, .link:focus": {
    color: `${info.main} !important`,
  },
  "@media print": {
    body: {
      "*": {
        visibility: "hidden",
      },
    },
    ".page-break": {
      clear: "both",
      pageBreakAfter: "always",
    },
    ".printable-content, .printable-content *": {
      visibility: "visible",
    },
    ".printable-content": {
      position: "absolute",
      left: 0,
      top: 0,
    },
    ".not-printable-content": {
      visibility: "hidden",
    }
  },
};

export default globals;
