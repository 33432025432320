import { useEffect, useState, useRef } from "react";

// DateJS
import dayjs from "dayjs";

// react-to-print
import { useReactToPrint } from "react-to-print";

import { useNavigate } from "react-router-dom";

// @mui material components
import Icon from "@mui/material/Icon";
import SpeedDial from "@mui/material/SpeedDial";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import StaffLayout from "layouts/dashboard/components/Staff/Layout";
import PrintableItems from "./components/PrintableItems"

// HTTP Service
import HttpService from "services/http-service";

const ItemsSummaryStaff = () => {
  const userTid = localStorage.getItem("tiv");
  const navigate = useNavigate();
  const [items, setItems] = useState({ am: [], pm: [] });

  const lsOrders = JSON.parse(localStorage.getItem("orders") || "{}");
  const [deliveryDateFilter, setDeliveryDateFilter] = useState(lsOrders.filters?.delivery_date ? dayjs(lsOrders.filters.delivery_date) : dayjs());

  const [openDrawer, setOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);

  if (userTid !== "3") {
    navigate("/report/items");
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintAction = () => {
    if (isPrinting) {
      handlePrint();
      setIsPrinting(false);
    }
  };

  const toggleDrawer = (open) => {
    setOpenDrawer(open);
  };

  const fetchData = async () => {
    let params = {
      filters: {
        delivery_date: deliveryDateFilter.format("YYYY-MM-DD")
      }
    };
    setIsLoading(true);

    try {
      const response = await HttpService.get("/kitchen/staff/order-lines/summary", params);
      setItems(response.data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClickFilter = (e) => {
    e.preventDefault();
    toggleDrawer(false);
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    // set the filters here to localStorage
    localStorage.setItem("orders", JSON.stringify({
      filters: {
        delivery_date: deliveryDateFilter
      }
    }));
  }, [deliveryDateFilter]);

  useEffect(() => {
    const handlePrintFromMenu = () => {
      console.log("testing");
      handlePrintAction();
      setIsPrinting(true);
    };

    // Attach an event listener to the document for the print action
    document.addEventListener("printFromMenu", handlePrintFromMenu);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("printFromMenu", handlePrintFromMenu);
    };
  }, []); // No dependencies, so it runs once on mount

  return (
    <StaffLayout>
      {isLoading == false ? (
        <Container>
          <MDBox
            py={1}
            px={{ xs: 0 }}
            my={4}
          >
            <MDBox mb={10}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  mb={3}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="md"
                  coloredShadow="info"
                >
                  <MDTypography variant="h4" color="white">
                    AM
                  </MDTypography>
                </MDBox>
                <MDBox mx={2} my={2}>
                  <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="right" width="10%">Completed</TableCell>
                        <TableCell align="right" width="10%">Quantity</TableCell>
                      </TableRow>
                      {items.am.map((item, index) => (
                        <TableRow
                          key={`${item.name}-${index}`}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell align="left">{item.item_description}</TableCell>
                          <TableCell align="right">{item.completed}</TableCell>
                          <TableCell align="right">{item.item_quantity}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </MDBox>
              </Card>
            </MDBox>
            <MDBox>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  mb={3}
                  py={2}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="md"
                  coloredShadow="info"
                >
                  <MDTypography variant="h4" color="white">
                    PM
                  </MDTypography>
                </MDBox>
                <MDBox mx={2} my={2}>
                  <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">Name</TableCell>
                        <TableCell align="right" width="10%">Completed</TableCell>
                        <TableCell align="right" width="10%">Quantity</TableCell>
                      </TableRow>
                      {items.pm.map((item, index) => (
                        <TableRow
                          key={`${item.name}-${index}`}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell align="left">{item.item_description}</TableCell>
                          <TableCell align="right">{item.completed}</TableCell>
                          <TableCell align="right">{item.item_quantity}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </MDBox>
              </Card>
            </MDBox>
          </MDBox>
          <MDBox sx={{ display: "none"}}>
            <PrintableItems items={items} ref={componentRef} />
          </MDBox>
        </Container>
      ) : (
        <p>Loading here.</p>
      )}
      <SpeedDial
        ariaLabel="Filters"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={<Icon fontSize="small" color="inherit">settings</Icon>}
        onClick={() => toggleDrawer(true)}
      />
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: 360,
            "& .MuiDrawer-paper": {
              width: 360,
            },
          },
        }}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={4}
          pb={0.5}
          px={3}
        >
          <MDBox>
            <MDTypography variant="h5">Filters</MDTypography>
          </MDBox>

          <Icon
            sx={({ typography: { size } }) => ({
              fontSize: `${size.lg} !important`,
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              transform: "translateY(5px)",
            })}
            onClick={() => toggleDrawer(false)}
          >
            close
          </Icon>
        </MDBox>

        <Divider />

        <MDBox pt={2} px={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              fullWidth
              label="Delivery Date"
              value={deliveryDateFilter}
              onChange={(newValue) => setDeliveryDateFilter(newValue)}
            />
          </LocalizationProvider>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={1}
          px={3}
          lineHeight={1}
        >
          <MDButton fullWidth color="primary" onClick={(e) => handleClickFilter(e)}>Filter Items</MDButton>
        </MDBox>
        {(items.am.length !== 0 || items.pm.length !== 0) && (
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
            px={3}
            lineHeight={1}
          >
            <MDButton fullWidth color="secondary" onClick={() => handlePrint()}>Print Results</MDButton>
          </MDBox>
        )}

      </Drawer>
    </StaffLayout>
  );
};


export default ItemsSummaryStaff;
