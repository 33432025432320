import PropTypes from "prop-types";

import Chip from "@mui/material/Chip";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const User = ({ name, status }) => {
  const getStatusInfo = () => {
    if (status === 0) {
      return { chipColor: "warning", chipLabel: "Pending" };
    } else if (status === 2) {
      return { chipColor: "secondary", chipLabel: "Inactive" };
    } else {
      return { chipColor: "primary", chipLabel: "Active" };
    }
  };

  const { chipColor, chipLabel } = getStatusInfo();

  return (
    <>
      <MDBox sx={{ display: "inline-block" }}>
        <MDTypography variant="body2" mr={2}>
          {name}
        </MDTypography>
      </MDBox>
      <Chip color={chipColor} label={chipLabel} size="small" variant="outlined" />
    </>
  );
};

// Typechecking props for the Users
User.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
};

export default User;