// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import PaidIcon from "@mui/icons-material/Paid";
import SellIcon from "@mui/icons-material/Sell";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import InventoryIcon from "@mui/icons-material/Inventory";
import MopedIcon from "@mui/icons-material/Moped";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import CancelIcon from "@mui/icons-material/Cancel";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";

import MDButton from "components/MDButton";

const OrderStatus = ({ status }) => {
  const statusMappings = {
    1: { text: "Order Received", color: "info", icon: <CallReceivedIcon /> },
    2: { text: "Waiting for Payment", color: "warning", icon: <SellIcon /> },
    3: { text: "Payment Confirmed", color: "info", icon: <PaidIcon /> },
    4: { text: "Preparing Dishes", color: "primary", icon: <MicrowaveIcon /> },
    5: { text: "For Packaging", color: "warning", icon: <InventoryIcon /> },
    6: { text: "Waiting for Rider", color: "primary", icon: <MopedIcon /> },
    7: { text: "Out for Delivery", color: "warning", icon: <MopedIcon /> },
    8: { text: "Completed", color: "success", icon: <Icon>check</Icon> },
    9: { text: "Cancelled", color: "error", icon: <CancelIcon /> },
    10: { text: "On-Hold", color: "secondary", icon: <ProductionQuantityLimitsIcon /> },
    11: { text: "Refund", color: "error", icon: <MoneyOffIcon /> },
  };

  const { text: statusText, color: buttonColor, icon: statusIcon } = statusMappings[status] || {
    text: "Completed",
    color: "success",
    icon: <Icon>check</Icon>,
  };

  return (
    <MDButton
      variant="contained"
      color={buttonColor}
      startIcon={statusIcon}
      circular={true}
      size="small"
    >
      {statusText}
    </MDButton>
  );
};

OrderStatus.propTypes = {
  status: PropTypes.number,
};

export default OrderStatus
