import { useState, useRef } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";

// Data
import usersTableData from "pages/Users/data/usersTableData";

// Local Components
import CreateUser from "pages/Users/components/CreateUser";
import EditUser from "pages/Users/components/EditUser";
import ChangePassword from "pages/Users/components/ChangePassword";

const Users = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [clickedUserId, setClickedUserId] = useState(null);

  const editUserRef = useRef();
  const changePasswordUserRef = useRef();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditUser = () => {
    editUserRef.current.openEditDialog(clickedUserId);
    handleClose();
  };

  const handleChangePassword = () => {
    changePasswordUserRef.current.openChangePasswordDialog(clickedUserId);
    handleClose();
  };

  const successChangedPassword = () => {
    console.log("display a prompt for successful password change.");
  };

  const showMenu = (e, userId) => {
    setClickedUserId(userId);
    setAnchorEl(e.currentTarget);
  };

  const { columns, rows, fetchUsers } = usersTableData({
    onActionClicked: showMenu,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                mb={3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="md"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white">
                  Users
                </MDTypography>
              </MDBox>
            </Card>
            <Card sx={{ marginTop: "10px" }}>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  canSearch={false}
                  canSort={true}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder={false}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <CreateUser onSuccessCreated={fetchUsers} />
      <EditUser ref={editUserRef} onSuccessUpdated={fetchUsers} />
      <ChangePassword
        ref={changePasswordUserRef}
        onSuccessChangedPassword={successChangedPassword}
      />
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleEditUser}>Edit</MenuItem>
        <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
      </Menu>
    </DashboardLayout>
  );
};

export default Users;
