import PropTypes from "prop-types";

import Chip from "@mui/material/Chip";

const UserRole = ({ role }) => {
  const getStatusInfo = () => {
    if (role === 1) {
      return { chipColor: "primary", chipLabel: "Developer" };
    } else if (role === 2) {
      return { chipColor: "secondary", chipLabel: "Admin" };
    } else if (role === 3) {
      return { chipColor: "info", chipLabel: "Staff" };
    } else {
      return { chipColor: "warning", chipLabel: "Unknown" };
    }
  };

  const { chipColor, chipLabel } = getStatusInfo();

  return <Chip color={chipColor} label={chipLabel} size="small" />;
};

// Typechecking props for the Users
UserRole.propTypes = {
  role: PropTypes.number.isRequired,
};

export default UserRole;