import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";

// Material Dashboard 2 React base styles
import colors from "assets/theme/base/colors";

// Material Dashboard 2 React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { transparent } = colors;

const AGDivider = styled(Divider)(() => ({
  backgroundColor: transparent.main,
  backgroundImage: "none !important",
  height: "auto",
  borderBottom: "none",
  marginBottom: pxToRem(30),
  opacity: 0.25,
}));

export default AGDivider;
