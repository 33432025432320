/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import Dashboard from "layouts/dashboard";
import Orders from "pages/Orders";
import Customers from "pages/Customers";
import Users from "pages/Users";
import Riders from "pages/Riders";
import ItemsSummary from "pages/ItemsSummary";

import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";

// @mui icons
import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    icon: <Icon fontSize="small">listalt</Icon>,
    title: "Orders",
    route: "/orders",
    component: <Orders />,
  },
  {
    type: "collapse",
    name: "Customers",
    key: "customers",
    icon: <Icon fontSize="small">contacts</Icon>,
    title: "Customers",
    route: "/customers",
    component: <Customers />,
  },
  {
    type: "collapse",
    name: "Users",
    key: "users",
    icon: <PeopleAltOutlinedIcon fontSize="small" />,
    title: "Users",
    route: "/users",
    component: <Users />,
  },
  {
    type: "collapse",
    name: "Riders",
    key: "riders",
    icon: <DeliveryDiningOutlinedIcon fontSize="small" />,
    title: "Riders",
    route: "/riders",
    component: <Riders />,
  },
  {
    type: "divider",
    key: "divider1",
  },
  {
    type: "title",
    name: "Reports",
    key: "reports-title",
    title: "Reports",
  },
  {
    type: "collapse",
    name: "Items Summary",
    key: "report",
    icon: <Icon fontSize="small">listalt</Icon>,
    title: "Items Summary",
    route: "/report/items",
    component: <ItemsSummary />,
  },
];

export default routes;
