// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";

function Footer() {
  const { size } = typography;

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row-reverse" }}
      justifyContent="space-between"
      alignItems="right"
      px={1.5}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()}, made with
        <MDBox fontSize={size.md} color="text" mb={-0.5} mx={0.25}>
          <Icon color="inherit" fontSize="inherit">
            favorite
          </Icon>
        </MDBox>
        by
        <Link href="https://skybrewph.com" target="_blank">
          <MDTypography variant="button" fontWeight="medium">
            &nbsp;Skybrew&nbsp;
          </MDTypography>
        </Link>
        &amp;
        <Link href="https://abundantgeeks.com" target="_blank">
          <MDTypography variant="button" fontWeight="medium">
            &nbsp;Abundant Geeks&nbsp;
          </MDTypography>
        </Link>
        for a better web.
      </MDBox>
    </MDBox>
  );
}

export default Footer;
