import { forwardRef } from "react";

import PropTypes from "prop-types";

import MDBox from "components/MDBox";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const PrintableItems = forwardRef(({ rows }, ref) => {

  return (
    <div ref={ref}>
      <MDBox className="printable-content" sx={{ display: {xs: "block", displayPrint: "block"} }}>
        <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell align="right" width="10%">Quantity</TableCell>
              <TableCell align="left">Name</TableCell>
            </TableRow>
            {rows.map((row, index) => (
              <TableRow
                key={`${row.name}-${index}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="right">{row.quantity}</TableCell>
                <TableCell align="left">{row.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </MDBox>
    </div>
  );
});

PrintableItems.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      quantity: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      // Add other prop types as needed
    })
  ).isRequired,
};

PrintableItems.displayName = "PrintableItems";

export default PrintableItems;
