import { forwardRef } from "react";

import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const PrintableItems = forwardRef(({ items }, ref) => {

  return (
    <div ref={ref} className="printable-content">
      <MDBox sx={{ display: {xs: "block", displayPrint: "block"} }}>
        <MDTypography variant="h4">
          AM
        </MDTypography>
      </MDBox>
      <MDBox sx={{ display: {xs: "block", displayPrint: "block"} }}>
        <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell align="right" width="10%">Quantity</TableCell>
              <TableCell align="left">Name</TableCell>
            </TableRow>
            {items.am.map((item, index) => (
              <TableRow
                key={`${item.name}-${index}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="right">{item.item_quantity}</TableCell>
                <TableCell align="left">{item.item_description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </MDBox>
      <MDBox sx={{ display: {xs: "block", displayPrint: "block"} }}>
        <MDTypography variant="h4">
          PM
        </MDTypography>
      </MDBox>
      <MDBox sx={{ display: {xs: "block", displayPrint: "block"} }}>
        <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
          <TableBody>
            <TableRow>
              <TableCell align="right" width="10%">Quantity</TableCell>
              <TableCell align="left">Name</TableCell>
            </TableRow>
            {items.pm.map((item, index) => (
              <TableRow
                key={`${item.name}-${index}`}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="right">{item.item_quantity}</TableCell>
                <TableCell align="left">{item.item_description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </MDBox>
    </div>
  );
});

PrintableItems.propTypes = {
  items: PropTypes.object.isRequired,
};

PrintableItems.displayName = "PrintableItems";

export default PrintableItems;
