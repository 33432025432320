// @mui components
import Card from "@mui/material/Card";

// Material Design Component
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TimelineList from "components/Timeline/TimelineList";
import TimelineItem from "components/Timeline/TimelineItem";

const OrderActivities = () => {
  return (
    <Card>
      <MDBox mx={{ xs: 3, md: 4 }}>
        <Card sx={{ boxShadow: "none" }}>
          <MDBox py={2}>
            <MDTypography variant="h5" textTransform="capitalize">
              Activity
            </MDTypography>
          </MDBox>
          <MDBox>
            <TimelineList>
              <TimelineItem
                color="success"
                icon="shopping_cart"
                title="Order Received"
                description="Order confirmed! Our system has registered your purchase, and we're gearing up to make sure your items are on their way to you."
                dateTime="25 NOV 7:20 PM"
                badges={["design"]}
              />
              <TimelineItem
                color="error"
                icon="paid"
                title="Payment Confirmed"
                description="Great news! Your payment is officially confirmed. Our team is now gearing up to fulfill your order promptly."
                dateTime="25 NOV 11:00 PM"
                badges={["order", "#1832412"]}
              />
              <TimelineItem
                icon="shopping_cart"
                title="Preparing Dishes"
                description="The kitchen is bustling with activity as we prepare the delicious dishes you've ordered. Get ready for a flavorful experience!"
                dateTime="24 DEC 9:34 AM"
                badges={[]}
              />
              <TimelineItem
                color="warning"
                icon="delivery_dining"
                title="Out for Delivery"
                description="Your order is now out for delivery, and our team is on the road to ensure a timely arrival. Sit back, relax, and anticipate the arrival of your package."
                dateTime="24 DEC 05:30 PM"
                badges={[]}
              />
              <TimelineItem
                color="success"
                icon="check"
                title="Completed"
                description="Success! Your order has been fulfilled and marked as completed. If you have any feedback or questions, feel free to reach out."
                dateTime="24 DEC 06:00 PM"
                badges={[]}
                lastItem
              />
            </TimelineList>
          </MDBox>
        </Card>
      </MDBox>
    </Card>
  );
};

export default OrderActivities;
