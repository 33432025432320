import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

// react-to-print
import { useReactToPrint } from "react-to-print";

// DateJS
import dayjs from "dayjs";

// @mui material components
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";

// @mui-x
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";
import DataTable from "components/Tables/DataTable";

// Data
import ordersTableData from "pages/Orders/data/ordersTableData";
import EditOrder from "pages/Orders/components/EditOrder";
import PrintableOrders from "pages/Orders/components/PrintableOrders";
import StatusDialog from "pages/Orders/components/StatusDialog";

import HttpService from "services/http-service";

const Orders = () => {
  const editOrderRef = useRef();

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const [clickedOrder, setClickedOrder] = useState(null);
  const [orders, setOrders] = useState([]);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [printableRendered, setPrintableRendered] = useState(false);
  const [loadingData, setLoadingData] = useState(false);

  const lsOrders = JSON.parse(localStorage.getItem("orders") || "{}");

  const [statusValue, setStatusValue] = useState(lsOrders.filters?.status || -1);
  const [filterOrderID, setFilterOrderID] = useState(lsOrders.filters?.order_id || "");
  const [deliveryDate, setDeliveryDate] = useState(lsOrders.filters?.delivery_date ? dayjs(lsOrders.filters.delivery_date) : dayjs());
  const [pageIndex, setPageIndex] = useState(lsOrders.pagination?.page_index || 0);

  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [orderStatus, setOrderStatus] = useState(null);
  const [orderID, setOrderID] = useState(null);

  const navigate = useNavigate();

  const printableRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });

  const fetchDataAndPrint = async () => {
    let fetchDataParams = {
      filters: {
        status: statusValue,
        order_id: filterOrderID,
        delivery_date: deliveryDate ? deliveryDate.format("YYYY-MM-DD") : null,
      },
      order_lines: 1,
    };
    // set the loading backdrop true here.
    setLoadingScreen(true);
    try {
      const response = await HttpService.get("/kitchen/orders", fetchDataParams);

      setOrders(response.data.orders);
      const checkPrintableRendered = setInterval(() => {
        if (printableRendered) {
          clearInterval(checkPrintableRendered); // Stop checking when rendered
          handlePrint();
          setLoadingScreen(false);
        }
      }, 500);
    } catch (e) {
      console.log(e);
    }
  };

  const handleViewOrder = () => {
    navigate("/orders/" + clickedOrder.order_id + "/" + clickedOrder.external_order_id);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEditOrder = () => {
    editOrderRef.current.openEditDialog(clickedOrder.order_id);
    handleCloseMenu();
  }

  const handleStatusChange = (event) => {
    setStatusValue(event.target.value);
  };

  const handleOrderIDChange = (event) => {
    setFilterOrderID(event.target.value);
  };

  const showMenu = (e, orderID, externalOrderID) => {
    setClickedOrder({
      order_id: orderID,
      external_order_id: externalOrderID,
    });

    setAnchorEl(e.currentTarget);
  };

  const handleSuccessUpdate = (orderID, modifiedData) => {
    modifyOrder(orderID, modifiedData);
  };

  const handlePageChange = (dtPageIndex) => {
    setPageIndex(dtPageIndex)
  };

  const handleStatusClicked = (e, order_id, status) => {
    setOrderID(order_id);
    setOrderStatus(status);
    setOpenStatusDialog(true);
  };

  const { columns, rows, fetchData, modifyOrder } = ordersTableData({ onActionClicked: showMenu, onStatusClicked: handleStatusClicked });

  const statusOptions = [
    {
      value: "-1",
      label: "All",
    },
    {
      value: "1",
      label: "Order Received",
    },
    {
      value: "2",
      label: "Waiting for Payment",
    },
    {
      value: "3",
      label: "Payment Confirmed",
    },
    {
      value: "4",
      label: "Preparing Dishes",
    },
    {
      value: "5",
      label: "For Packaging",
    },
    {
      value: "6",
      label: "Waiting for Dispatch",
    },
    {
      value: "7",
      label: "Out for Delivery",
    },
    {
      value: "8",
      label: "Completed",
    },
    {
      value: "9",
      label: "Cancelled",
    },
    {
      value: "10",
      label: "On-Hold",
    },
    {
      value: "11",
      label: "Refund",
    },
  ];

  const submitHandler = async (e) => {
    e.preventDefault();

    let fetchDataParams = {
      filters: {
        status: statusValue,
        order_id: filterOrderID,
        delivery_date: deliveryDate ? deliveryDate.format("YYYY-MM-DD") : null
      }
    };

    try {
      setLoadingData(true);
      setPageIndex(0);
      fetchData(fetchDataParams).then(() => {
        setLoadingData(false);
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // set the filters here from localStorage.

    let fetchDataParams = {
      filters: {
        status: statusValue,
        order_id: filterOrderID,
        delivery_date: deliveryDate.format("YYYY-MM-DD"),
      }
    };

    setLoadingData(true);
    fetchData(fetchDataParams).then(() => {
      setLoadingData(false);
    }); // Initially fetch all data
  }, []);

  useEffect(() => {
    // set the filters here to localStorage
    localStorage.setItem("orders", JSON.stringify({
      filters: {
        status: statusValue,
        order_id: filterOrderID,
        delivery_date: deliveryDate
      },
      pagination: {
        page_index: pageIndex
      }
    }));
  }, [statusValue, filterOrderID, deliveryDate, pageIndex]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3} sx={{ minHeight: "calc(100vh - 150px)"}}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                mb={3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="md"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white">
                  Orders
                </MDTypography>
              </MDBox>
              <MDBox component="form" role="form" method="POST" onSubmit={submitHandler}>
                <Grid container spacing={2} mb={2} mx={1} sx={{ width: "calc(100% - 32px)"}}>
                  <Grid item xs={6} sm={4} md={4} lg={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        label="Delivery Date"
                        value={deliveryDate}
                        onChange={(newValue) => setDeliveryDate(newValue)}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} sm={4} md={4} lg={2}>
                    <MDInput
                      select
                      placeholder="Status"
                      value={statusValue}
                      defaultValue={statusValue}
                      label="Status"
                      fullWidth
                      onChange={handleStatusChange}
                      variant="outlined"
                    >
                      {statusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </MDInput>
                  </Grid>
                  <Grid item xs={6} sm={4} md={4} lg={2}>
                    <MDInput
                      label="Order ID"
                      placeholder="Order ID"
                      fullWidth
                      onChange={handleOrderIDChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={4}>
                    <MDBox sx={{ display: "inline" }}>
                      <MDButton variant="contained" color="primary" type="submit">
                        Filter
                      </MDButton>
                    </MDBox>
                    {rows.length > 0 && (
                      <MDBox ml={1} sx={{ display: "inline" }}>
                        <MDButton variant="contained" color="success" onClick={fetchDataAndPrint} startIcon={<Icon>print</Icon>}>
                          Print Results
                        </MDButton>
                      </MDBox>
                    )}
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
            <Card sx={{ marginTop:"10px" }}>
              <MDBox pt={3}>
                {!loadingData ? (
                  <DataTable
                    table={{ columns, rows }}
                    canSearch={false}
                    canSort={false}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={true}
                    noEndBorder={false}
                    dataTablePageIndex={pageIndex}
                    onPageChange={handlePageChange}
                  />
                ) : (
                  <MDBox
                    p={3}
                    pt={5}
                    textAlign="center"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <MDBox>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                      </Grid>
                      <Grid item xs={3}>
                        <MDBox>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                      </Grid>
                      <Grid item xs={3}>
                        <MDBox>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                      </Grid>
                      <Grid item xs={3}>
                        <MDBox>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                      </Grid>
                      <Grid item xs={12}>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <EditOrder ref={editOrderRef} onSuccessUpdate={handleSuccessUpdate} />
      <StatusDialog
        openDialog={openStatusDialog}
        onCloseDialog={() => {
          setOpenStatusDialog(false);
        }}
        status={orderStatus}
        orderID={orderID}
        statusOptions={statusOptions}
        onSuccessUpdate={handleSuccessUpdate}
      />
      <Menu
        id="action-order-menu"
        aria-labelledby="action-order-button"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleViewOrder}>View Order Details</MenuItem>
        <MenuItem onClick={handleEditOrder}>Edit Order</MenuItem>
      </Menu>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingScreen}
      >
        <CircularProgress color="inherit" />
        <MDTypography variant="h3" color="white" ml={3}>
          Please wait while preparing print preview.
        </MDTypography>
      </Backdrop>
      <MDBox sx={{ display: "none"}}>
        <PrintableOrders orders={orders} ref={printableRef} onRender={setPrintableRendered} />
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Orders;
