import { useState } from "react";

import MDTypography from "components/MDTypography";

import HttpService from "services/http-service";

export default function Data() {
  const [orderLines, setOrderLines] = useState([]);

  const fetchData = async (filters) => {
    try {
      const response = await HttpService.get("/kitchen/order-lines/summary", filters);

      setOrderLines(response.data || []);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const tableData = {
    columns: [
      { Header: "quantity", accessor: "quantity", align: "right", width:"10%" },
      { Header: "name", accessor: "name", align: "left"},
    ],
    rows: orderLines.map(orderLine => ({
      quantity: (
        <MDTypography variant="body2">{orderLine.item_quantity}</MDTypography>
      ),
      name: (
        <MDTypography variant="body2">{orderLine.item_description}</MDTypography>
      ),
    })),
  };

  return { ...tableData, fetchData, orderLines };
}
