import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

const ProtectedRoute = ({ isAuthenticated, children }) => {

  if (!isAuthenticated) {
    return <Navigate to="/login" replace={true} />;
  }

  return children;
};

// Prop types validation
ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.any,
  children: PropTypes.node.isRequired
};

export default ProtectedRoute;