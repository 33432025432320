import { useState, forwardRef, useImperativeHandle } from "react";

import PropTypes from "prop-types";

// @mui components
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";

// material design components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// HTTP service
import HttpService from "services/http-service";

const ChangePasswordDialog = forwardRef(({ onSuccessPasswordChanged }, ref) => {
  const [riderID, setRiderID] = useState(null);
  const [open, setOpenDialog] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const [inputs, setInputs] = useState({
    name: "",
    password: "",
    confirmPassword: "",
    email: "",
    contactNumber: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    password: false,
    email: false,
    confirmPassword: false,
    contactNumber: false,
  });

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    password: "",
    email: "",
    confirmPassword: "",
    contactNumber: "",
  });

  const resetFields = () => {
    setInputs({
      password: "",
      confirmPassword: "",
    });

    setErrors({
      password: "",
      confirmPassword: false,
    });

    setErrorMessages({
      password: "",
      confirmPassword: "",
    });
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));

    setErrorMessages((prevMessages) => ({
      ...prevMessages,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleCloseDialog = (e, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpenDialog(false);
    resetFields();
  };

  const validateInputs = () => {
    let hasErrors = false;

    if (inputs.password.trim().length < 8) {
      setErrors((prevErrors) => ({ ...prevErrors, password: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        password: "Password must be at least 8 characters",
      }));
      hasErrors = true;
    }

    if (inputs.confirmPassword.trim().length < 8) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        confirmPassword: "Password must be at least 8 characters",
      }));
      hasErrors = true;
    }

    if (inputs.password !== inputs.confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: true,
        confirmPassword: true,
      }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        password: "Passwords do not match",
      }));
      hasErrors = true;
    }

    return !hasErrors;
  };

  const openDialog = async (riderID) => {
    setRiderID(riderID);
    setOpenDialog(true);
  };

  // Expose the openEditDialog function via ref
  useImperativeHandle(ref, () => ({
    openDialog,
  }));

  const requestChangePassword = async () => {
    try {
      let postData = {
        password: inputs.password,
        confirm_password: inputs.confirmPassword,
      };

      const response = await HttpService.post(
        "/kitchen/riders/" + riderID + "/password",
        postData,
      );

      if (response.status == 200) {
        resetFields();
        onSuccessPasswordChanged();
        handleCloseDialog();
      }

      setLoadingButton(false);
    } catch (e) {
      if (e.data.error && e.data.error.details) {
        const newErrors = { ...errors };
        const newErrorMessages = { ...errorMessages };

        e.data.error.details.forEach((detail) => {
          if (detail.field && detail.field in newErrors) {
            newErrors[detail.field] = true;
            newErrorMessages[detail.field] = detail.message;
          }
        });

        setErrors(newErrors);
        setErrorMessages(newErrorMessages);
      }

      setLoadingButton(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }

    requestChangePassword();
  };
  return (
    <MDBox sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>
      <Dialog
        open={open}
        onClose={handleCloseDialog}
        fullWidth={true}
        maxWidth="sm"
      >
        <MDBox component="form" onSubmit={handleFormSubmit}>
          <DialogTitle>Create Rider</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} pt={1}>
              <Grid item xs={12} md={12}>
                <MDInput
                  name="password"
                  label="Password"
                  fullWidth
                  type="password"
                  onChange={handleFieldChange}
                  value={inputs.password}
                  error={errors.password}
                  helperText={errorMessages.password}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <MDInput
                  name="confirmPassword"
                  label="Confirm Password"
                  fullWidth
                  type="password"
                  onChange={handleFieldChange}
                  value={inputs.confirmPassword}
                  error={errors.confirmPassword}
                  helperText={errorMessages.confirmPassword}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={handleCloseDialog}
              color="secondary"
              variant="text"
            >
              Close
            </MDButton>
            <MDButton disabled={loadingButton} type="submit" color="primary">
              {loadingButton && (
                <CircularProgress
                  color="warning"
                  size={20}
                  sx={{ marginRight: "5px" }}
                />
              )}
              Change password
            </MDButton>
          </DialogActions>
        </MDBox>
      </Dialog>
    </MDBox>
  );
});

ChangePasswordDialog.propTypes = {
  onSuccessPasswordChanged: PropTypes.func,
};

ChangePasswordDialog.defaultProps = {
  onSuccessPasswordChanged: () => { },
};

ChangePasswordDialog.displayName = "ChangePasswordDialog";

export default ChangePasswordDialog;
