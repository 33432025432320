import HttpService from "./http-service";

// Function to refresh the access token
const refreshToken = async () => {
  try {
    const response = await HttpService.post("/kitchen/token");
    if (response.status === 200) {
      // Successfully refreshed the token, no need to handle the new access token here.
      return true;
    } else {
      // Handle the case where the refresh token request failed.
      return false;
    }
  } catch (error) {
    // Handle any errors during the token refresh request.
    return false;
  }
};

export const setupAxiosInterceptors = (onUnauthenticated) => {
  const onRequestSuccess = async (config) => {
    return config;
  };
  const onRequestFail = (error) => Promise.reject(error);

  HttpService.addRequestInterceptor(onRequestSuccess, onRequestFail);

  const onResponseSuccess = async (response) => response;
  const onResponseFail = async (error) => {
    if (typeof error.response !== "undefined"){
      const status = error.response.status;
      if (status === 403 || status === 401) {
        return refreshToken(onUnauthenticated)
          .then( async (refreshed) => {
            if (!refreshed) {
              onUnauthenticated();
            } else {
              // Retry the original request with the new token
              const originalRequest = error.config;
              return await HttpService._axios(originalRequest);
            }
          }).catch((refreshError) => {
            console.error("Error refreshing token:", refreshError);
            onUnauthenticated();
            return Promise.reject(refreshError);
          });
      }
    }

    return Promise.reject(error);
  };

  HttpService.addResponseInterceptor(onResponseSuccess, onResponseFail);
};