import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";

// @mui material components
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import HttpService from "services/http-service";

export default function Data({ onEditButtonClicked }) {
  const [customers, setCustomers] = useState([]);

  const fetchCustomers = async (filters) => {
    try {
      const response = await HttpService.get("/kitchen/customers", filters);

      setCustomers(response.data.customers || []);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const modifyCustomer = (customerID, modifiedData) => {
    const numericCustomerId = parseInt(customerID, 10);

    const recordIndex = customers.findIndex(record => record.id === numericCustomerId);

    const newRows = [...customers];
    newRows[recordIndex] = { ...newRows[recordIndex], ...modifiedData };

    setCustomers(newRows);
  };

  const CustomerID = ({ id }) => (
    <MDTypography variant="body1" fontWeight="medium">
      <Link href={`/customers/${id}`} to={`/customers/${id}`} underline="always">{id}</Link>
    </MDTypography>
  );

  // Typechecking props for the OrderID
  CustomerID.propTypes = {
    id: PropTypes.number,
  };

  const tableData = {
    columns: [
      { Header: "customer id", accessor: "id", align: "left" },
      { Header: "name", accessor: "name", align: "left" },
      { Header: "contact number", accessor: "contact_number", align: "left" },
      { Header: "email address", accessor: "email", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows: customers.map(customer => ({
      id: <CustomerID id={customer.id} />,
      name: <MDTypography variant="body2">{customer.name}</MDTypography>,
      contact_number: <MDTypography variant="body2">{customer.contact_number}</MDTypography>,
      email: <MDTypography variant="body2">{customer.email}</MDTypography>,
      action: (
        <>
          <MDBox color="text" px={2}>
            <Stack direction="row" spacing={2}>
              <MDButton size="small" variant="outlined" color="primary" component={NavLink} to={`/customers/${customer.id}/orders`}>Orders</MDButton>
              <MDButton
                size="small"
                variant="outlined"
                color="info"
                onClick={() => onEditButtonClicked(customer.id)}
              >
                Edit
              </MDButton>
            </Stack>
          </MDBox>
        </>
      ),
    })),
  };

  return { ...tableData, fetchCustomers, modifyCustomer };
}
